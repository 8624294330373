<template>
  <div class="text-center mt-5">
    <!-- <img :src="imgUrl" alt="" srcset=""> -->
    <iframe :src="imgUrl"></iframe>
  </div>
</template>

<script>
import * as sesstion from "@/store/localStorage";
export default {
    data(){
        return{
            imgUrl:sesstion.getLocalData("imgUrl")
        }
    }
}
</script>

<style>

</style>